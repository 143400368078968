define("discourse/plugins/discourse-audio-message/discourse/controllers/audio-message", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "@ember/object", "@uppy/core", "@uppy/xhr-upload", "discourse-common/lib/get-url", "discourse-common/lib/get-owner", "discourse/lib/load-script"], function (_exports, _controller, _modalFunctionality, _object, _core, _xhrUpload, _getUrl, _getOwner, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_obj = {
    isRecording: false,
    hasRecording: false,
    audioData: null,
    audioRecorder: null,
    elapsedTime: "0:00",
    startTime: null,
    audioUrl: null,
    uploading: false,
    uploadProgress: 0,
    init() {
      this._super(...arguments);
      Promise.all([(0, _loadScript.default)("/plugins/discourse-audio-message/javascripts/audiorecorder.js"), (0, _loadScript.default)("/plugins/discourse-audio-message/javascripts/mp3worker.js")]).then(() => {
        AudioRecorder.preload("/plugins/discourse-audio-message/javascripts/mp3worker.js");
      });
    },
    startRecording() {
      this.audioRecorder = new AudioRecorder({
        encoderBitRate: 128,
        streaming: true
      });
      const mimeType = 'audio/mp3';
      let audioChunks = [];
      this.audioRecorder.onstart = () => {
        this.set('isRecording', true);
        this.set('startTime', Date.now());
        this.timer = setInterval(() => this.updateElapsedTime(), 1000);
      };
      this.audioRecorder.ondataavailable = data => {
        audioChunks.push(data);
      };
      this.audioRecorder.onstop = () => {
        this.audioData = new Blob(audioChunks, {
          type: mimeType
        });
        this.audioUrl = URL.createObjectURL(this.audioData);
        this.set('hasRecording', true);
        this.set('isRecording', false);
      };
      this.audioRecorder.start();
    },
    stopRecording() {
      if (this.audioRecorder) {
        this.audioRecorder.stop();
        this.set('isRecording', false);
        clearInterval(this.timer);
      }
    },
    updateElapsedTime() {
      let elapsedTime = Date.now() - this.startTime;
      this.set('elapsedTime', this.formatTime(elapsedTime));
    },
    formatTime(ms) {
      let seconds = Math.floor(ms / 1000 % 60);
      let minutes = Math.floor(ms / 1000 / 60 % 60);
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return `${minutes}:${seconds}`;
    },
    discardRecording() {
      if (window.confirm("Are you sure you want to discard this recording?")) {
        this.reset();
      }
    },
    attachAudioToPost() {
      if (this.audioData) {
        let file = new File([this.audioData], 'recording.mp3', {
          type: 'audio/mp3'
        });
        const uppy = new _core.default({
          debug: true,
          autoProceed: true,
          onBeforeUpload: files => {
            this.set('uploading', true);
          }
        });
        uppy.use(_xhrUpload.default, {
          endpoint: (0, _getUrl.default)("/uploads.json"),
          headers: {
            "X-CSRF-Token": this.session.csrfToken
          }
        });
        uppy.addFile({
          name: file.name,
          type: file.type,
          data: file
        });
        uppy.on('upload-progress', (file, progress) => {
          this.set('uploadProgress', Math.round(progress.bytesUploaded / progress.bytesTotal * 100));
        });
        uppy.on('upload-success', (file, response) => {
          // The upload was successful. Now attach the audio to the post.
          const composer = (0, _getOwner.getOwner)(this).lookup('controller:composer');
          composer.model.appendText(`\n![recording|audio](${response.body.short_url})`);
          this.reset();
          this.send("closeModal");
        });
        uppy.on('upload-error', (file, error, response) => {
          // The upload failed.
          console.log("Failed to upload audio: " + error);
          this.set('uploading', false);
        });
        uppy.upload();
      }
    },
    onClose() {
      if (this.isRecording) {
        this.stopRecording();
        this.reset();
        this._super();
      }
    },
    reset() {
      URL.revokeObjectURL(this.audioUrl);
      this.setProperties({
        isRecording: false,
        hasRecording: false,
        audioData: null,
        audioRecorder: null,
        elapsedTime: "0:00",
        startTime: null,
        audioUrl: null,
        uploading: false,
        uploadProgress: 0
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "startRecording", [_object.action], Object.getOwnPropertyDescriptor(_obj, "startRecording"), _obj), _applyDecoratedDescriptor(_obj, "stopRecording", [_object.action], Object.getOwnPropertyDescriptor(_obj, "stopRecording"), _obj), _applyDecoratedDescriptor(_obj, "discardRecording", [_object.action], Object.getOwnPropertyDescriptor(_obj, "discardRecording"), _obj), _applyDecoratedDescriptor(_obj, "attachAudioToPost", [_object.action], Object.getOwnPropertyDescriptor(_obj, "attachAudioToPost"), _obj)), _obj));
});